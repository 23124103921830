'use client'

import React, { FC } from 'react'
import Image from 'next/image'
import { usePathname } from 'next/navigation'
import { nextSanityImage } from '@/lib/sanity'
import { ImageAsset } from '@/types/site.interface'
import SafeLink from '@ignition/library/components/atoms/safe-link'

interface SiteLogoInterface {
  siteLogo: ImageAsset
  siteName?: string
}

// TODO: Version 5.0.0 of next-sanity-image library has removed support for the blur options.
// Remove when migration to next-sanity-image is done
// const nextSanityImageOptions: UseNextSanityImageOptions = {
//   enableBlurUp: false,
// }

export const SiteLogo: FC<SiteLogoInterface> = ({ siteLogo, siteName = 'Your Site' }) => {
  const pathname = usePathname()
  const Wrapper =
    pathname === '/'
      ? ({ children }) => (
          <button aria-label="Go Home" onClick={() => window.scrollTo(0, 0)}>
            {children}
          </button>
        )
      : ({ children }) => (
          <SafeLink href="/" aria-label="Go Home">
            <>{children}</>
          </SafeLink>
        )

  // const img = nextSanityImage(siteLogo, nextSanityImageOptions)
  const img = nextSanityImage(siteLogo)

  const aspectRatio = img.width / img.height

  const sizesString = `
    (min-width: 640px) ${Math.ceil(80 * aspectRatio)}px,
    (min-width: 768px) ${Math.ceil(90 * aspectRatio)}px,
    (min-width: 1024px) ${Math.ceil(90 * aspectRatio)}px,
    (min-width: 1280px) ${Math.ceil(100 * aspectRatio)}px,
    (min-width: 1536px) ${Math.ceil(200 * aspectRatio)}px,
    ${Math.ceil(50 * aspectRatio)}px
    `

  return (
    <Wrapper>
      <span className="sr-only">{siteName}</span>
      {img.src && <Image {...img} sizes={sizesString} alt={siteName} className="h-12 w-auto sm:h-20 object-contain" />}
    </Wrapper>
  )
}
