'use client'

import React, { FC } from 'react'
import { List } from 'phosphor-react-sc'
import { resolveURL } from '@/lib/routes'
import { ButtonLink, ImageAsset } from '@/types/site.interface'
import { Popover } from '@headlessui/react'
import { Button } from '../atoms/button'
import { SocialLinks } from '../elements/social-links'
import { SocialLink } from '../elements/social-links.interface'
import { SiteLogo } from '../molecules/site-logo'
import { GenericFlyoutMenu } from './generic-flyout-menu'
import { GenericHeaderMobile } from './generic-header-mobile'
import { MenuProps } from './menu.interface'
import { NavigationItem } from './navigation-item'
import { NavigationList } from './navigation-list'

const MobileBurger = () => (
  <div className="-mr-2 flex items-center md:hidden">
    <Popover.Button className="focus-ring-inset inline-flex items-center justify-center rounded-md p-2 text-text hover:text-primary focus:outline-none">
      <span className="sr-only">Open main menu</span>
      <List className="h-8 w-8" aria-hidden="true" />
    </Popover.Button>
  </div>
)

interface Header {
  menuMobilePrimary?: MenuProps
  menuDesktopPrimary?: MenuProps
  menuMobileSecondary?: MenuProps
  menuDesktopSecondary?: MenuProps
  navButtons?: ButtonLink[]
  socialLinks?: SocialLink[]
  siteLogo?: ImageAsset
  enableShadow?: boolean
  siteName?: string
}

const GenericHeader: FC<Header> = ({
  menuDesktopPrimary,
  menuMobilePrimary,
  menuMobileSecondary,
  navButtons,
  socialLinks,
  siteLogo,
  enableShadow,
  siteName,
}) => {
  return (
    <Popover as="header" className="sticky top-0 relative z-10">
      {enableShadow && <div className="pointer-events-none absolute inset-1 z-60 shadow-lg" aria-hidden="true" />}
      <div className="bg-background">
        <div className="relative mx-auto flex max-w-3xl items-center justify-between px-4 sm:px-6" aria-label="Global">
          <div className="flex flex-1 items-center">
            <div className="flex w-full items-center justify-between md:w-auto">
              {siteLogo && <SiteLogo siteLogo={siteLogo} siteName={siteName} />}
              <MobileBurger />
            </div>
            <NavigationList>
              {menuDesktopPrimary?.items?.map((item, index) => {
                return item.flyout ? (
                  <GenericFlyoutMenu key={`k-${index}-${item.id}`} title={item.title} items={item.dropdownItems} />
                ) : (
                  <NavigationItem key={`k-${index}-${item.id}`} title={item.title} href={resolveURL(item)} />
                )
              })}
            </NavigationList>
          </div>
          <div className="hidden md:flex justify-end">
            <div className="flex space-x-6 justify-center items-center mr-2">
              {socialLinks && <SocialLinks socialLinks={socialLinks} />}
            </div>
            {navButtons?.map(
              (button) =>
                button?.title && (
                  <Button
                    {...button}
                    key={button._key}
                    className={
                      button.variant === 'outline' ? 'font-semibold text py-3 mx-1' : 'font-semibold text mx-1'
                    }
                  >
                    {button?.title}
                  </Button>
                ),
            )}
          </div>
        </div>
      </div>
      <GenericHeaderMobile
        menuMobilePrimary={menuMobilePrimary}
        menuMobileSecondary={menuMobileSecondary}
        socialLinks={socialLinks}
        navButtons={navButtons}
        siteLogo={siteLogo}
        siteName={siteName}
      />
    </Popover>
  )
}

export default GenericHeader
