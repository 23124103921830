import { useNextSanityImage } from 'next-sanity-image'
import { Preview } from '@/types/site.interface'
import { createClient, QueryParams } from '@sanity/client'
import sanityImage from '@sanity/image-url'
import { SanityImageSource } from '@sanity/image-url/lib/types/types'

const options = {
  dataset: process.env.NEXT_PUBLIC_SANITY_PROJECT_DATASET,
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
  useCdn: true,
  apiVersion: '2021-10-21',
  fetch: true,
}

const optionsWithCredentials = {
  withCredentials: true,
  token: process.env.SANITY_API_TOKEN,
}

export const sanityClient = createClient({ ...options, ...optionsWithCredentials })
export const imageBuilder = sanityImage(sanityClient)

export function createPreviewClient(token: string) {
  return createClient({
    ...options,
    useCdn: false,
    token,
    apiVersion: '2023-06-20',
    ignoreBrowserTokenWarning: true,
    perspective: 'previewDrafts',
  })
}

export function getSanityClient(preview?: Preview) {
  if (preview?.active && preview?.token) {
    return createPreviewClient(preview.token)
  } else {
    return sanityClient
  }
}

interface NextFetchRequestConfig {
  revalidate?: number | false
  tags?: string[]
}

export async function fetchSanityClient(
  query: string,
  queryParams?: QueryParams,
  next?: NextFetchRequestConfig,
  preview?: Preview,
) {
  const client = await getSanityClient(preview)
  const daftEnable = preview?.active

  return client.fetch(query, queryParams, {
    cache: daftEnable ? undefined : 'force-cache',
    next: {
      ...(daftEnable && { revalidate: 0 }),
      ...next,
    },
  })
}

/*eslint-disable react-hooks/rules-of-hooks*/
export const nextSanityImage = (asset: SanityImageSource) => {
  return { ...useNextSanityImage(sanityClient, asset) }
}

// Sanity client form submission
const optionsWriterCredentials = {
  withCredentials: true,
  token: process.env.SANITY_SUBMISSION_API_TOKEN,
}

export const submissionSanityClient = createClient({ ...options, ...optionsWriterCredentials })
