import { notFound, redirect } from 'next/navigation'
import { LinkInfo } from '@/data/queries.interface'
import { ImageAsset } from '@/types/site.interface'
import logger from '@ignition/library/lib/logger'
import { hostNameToUrl } from '@ignition/library/utils/build-url'
import { isUndefined } from '@ignition/library/utils/object'
import { imageBuilder } from './sanity'

export const filterRequest = (path: string) => {
  const slug = decodeURIComponent(path?.toLowerCase())
  // feeds redirect
  const regexFeeds = /.*\/feed|feed\/.*/gim
  if (slug.match(regexFeeds)) {
    return redirect('/feeds')
  }

  const regex =
    /wp-.*|^intranet[/]?.*|sitemap\.txt|fileadmin|.*\/robots\.txt|\.well-known|https:.*|.*\/amp\/|\.php|\.html/gi
  if (slug.match(regex)) {
    logger.warn(`filterRequest hit: ${slug}`)
    return notFound()
  }
  return false
}

export const getSiteUrl = (hostName: string) => {
  const vercelEnv = process.env.VERCEL_ENV
  return hostNameToUrl(hostName, vercelEnv)
}

// determine if current page is active or not
export const getActive = (isStatic, pageSlug, router) => {
  if (isStatic !== false) {
    return isStatic == router.pathname.replace(/^\//g, '')
  } else {
    const slugs = [].concat(router.query.slug)
    const currentPath = slugs ? slugs.join('/') : router.asPath.replace(/^\//g, '')
    return currentPath == pageSlug
  }
}

export const getStaticRoute = (name) => {
  switch (name) {
    default:
      return false
  }
}

export const getDynamicRoute = (name) => {
  switch (name) {
    default:
      return false
  }
}

export const resolveURL = ({ url, page, type }: LinkInfo) => {
  switch (type) {
    case 'link':
      return url
    case 'page': {
      const slug = page?.slug
      return page?.isHome || isUndefined(slug) ? '/' : slug
    }
    default:
      return null
  }
}

export const imageUrl = (image: ImageAsset, width?: number, height?: number, format?, quality?) => {
  if (!image) return null
  let imgSrc = imageBuilder.image(image)

  if (width) {
    imgSrc = imgSrc.width(Math.round(width))
  }

  if (height) {
    imgSrc = imgSrc.height(Math.round(height))
  }

  if (format) {
    imgSrc = imgSrc.format(format)
  }

  if (quality) {
    imgSrc = imgSrc.quality(quality)
  }

  return imgSrc.fit('max').auto('format').url()
}
