'use client'

/* eslint-disable @next/next/no-img-element */
import React, { FC, Fragment } from 'react'
import { CaretDown, CaretUp } from 'phosphor-react-sc'
import { Popover, Transition } from '@headlessui/react'
import SafeLink from '@ignition/library/components/atoms/safe-link'
import { MenuItem } from './menu.interface'
import { prepareNavSections } from './prepare-nav-sections'

interface Props {
  items: MenuItem[]
}

export const FlyoutNavigation: FC<Props> = ({ items }) => {
  const columns = prepareNavSections(items)

  return (
    <>
      {columns.map((c, i) => (
        <div key={i}>
          {c.sections.map((s) => (
            <div key={s.title}>
              <h3 className="strech align-top uppercase">{s.title}</h3>
              <ul role="list" className="mt-5 space-y-6">
                {s.items.map((item) => (
                  <li key={item.url} className="flow-root">
                    <SafeLink
                      href={item.url}
                      className="-m-3 flex items-center rounded-md p-3 text-base font-medium text-mainnav-link hover:text-mainnav-link-hover"
                    >
                      {/* <item.icon className="h-6 w-6 flex-shrink-0 text-gray-400" aria-hidden="true" /> */}
                      <span className="ml-4">{item.title}</span>
                    </SafeLink>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      ))}
    </>
  )
}

export const GenericFlyoutMenu = ({ title, items }) => {
  return (
    <Popover>
      {({ open }) => (
        <>
          <Popover.Button className="text-base font-medium gray-900 hover:text-secondary hover:cursor-pointer focus:outline-none focus:ring-none">
            <span>{title}</span>
            {open ? (
              <CaretUp className="ml-1 inline font-bold gray-900 hover:text-secondary text-2xl " aria-hidden="true" />
            ) : (
              <CaretDown
                className="ml-1 inline font-medium gray-900 hover:text-secondary text-2xl"
                aria-hidden="true"
              />
            )}
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 -translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-1"
          >
            <Popover.Panel className="absolute inset-x-0 top-full z-50 hidden transform shadow-lg md:block bg-white">
              <div className=" container max-w-xl">
                <div className="absolute inset-0 flex">
                  <div className="w-1/2" />
                  <div className="w-1/2" />
                </div>
                <div className="relative mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
                  <nav className="grid gap-y-10 px-4 py-8 sm:grid-cols-2 sm:gap-x-8 sm:py-12 sm:px-6 lg:px-8 xl:pr-12">
                    <FlyoutNavigation items={items} />
                  </nav>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}
