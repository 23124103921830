'use client'

import React, { FC, SyntheticEvent } from 'react'
import cx from 'clsx'
import { Image as PhosphorImage } from 'phosphor-react-sc'
import { nextSanityImage } from '@/lib/sanity'
import { ImageAsset } from '@/types/site.interface'
import Image from '@ignition/library/components/atoms/image'

type ObjectPosition =
  | 'object-bottom'
  | 'object-center'
  | 'object-left'
  | 'object-left-bottom'
  | 'object-left-top'
  | 'object-right'
  | 'object-right-bottom'
  | 'object-right-top'
  | 'object-top'
  | 'object-center'
  | 'object-center-bottom'

interface ImageElement {
  alt?: string
  image?: ImageAsset
  sizes?: string
  quality?: number
  responsive?: boolean
  className?: string
  imgClassName?: string
  useFallbackImage?: boolean
  objectPosition?: ObjectPosition
  priority?: boolean
  fill?: boolean
  onLoad?: (result: SyntheticEvent<HTMLImageElement, Event>) => void
}

const defaultSizes = `(max-width: 1920px) 100vw`

// define our aspect ratio
export const aspectRatio = (customRatio?: string) => {
  if (customRatio) {
    switch (customRatio) {
      case '1:1':
        return 'aspect-square'
      case '2:1':
        return 'aspect-2/1'
      case '3:1':
        return 'aspect-3/1'
      case '5:7':
        return 'aspect-5/7'
      case '4:6':
        return 'aspect-4/6'
      case '16:9':
        return 'aspect-video'
      default:
        return 'aspect-auto'
    }
  }
}

const ImageElement: FC<ImageElement> = ({
  alt,
  image,
  sizes = defaultSizes,
  responsive,
  className,
  imgClassName,
  priority = false,
  objectPosition,
  useFallbackImage = false,
  fill,
  onLoad,
}) => {
  if (!image || !image?.asset) {
    return useFallbackImage ? <PhosphorImage className="w-full h-full text-gray-300" weight="light" /> : null
  }

  const { width, height, ...sanityProps } = nextSanityImage(image)
  return (
    <picture className={cx(aspectRatio(image.customRatio), className)} style={{ aspectRatio: image?.aspectRatio }}>
      <Image
        {...sanityProps}
        sizes={sizes}
        fill={fill}
        {...(fill ? {} : { width, height })}
        alt={alt || image.alt || image.asset?.alt || ''}
        className={cx(objectPosition, responsive && 'object-contain', fill && 'object-cover', imgClassName)}
        priority={priority}
        title={image.title}
        copyright={image.copyright}
        blurDataURL={image.lqip}
        onLoad={onLoad}
      />
    </picture>
  )
}

export default ImageElement
