export const buildUrl = (siteUrl: string, slug?: string, locale?: string) => {
  const builder: string[] = []
  if (siteUrl) {
    const urlFiltered = siteUrl.endsWith('/') ? siteUrl.slice(0, siteUrl.length - 1) : siteUrl
    builder.push(urlFiltered)
  }
  if (locale) {
    builder.push(locale)
  }
  if (slug) {
    const slugFiltered = slug.startsWith('/') ? slug.slice(1) : slug
    builder.push(slugFiltered)
  }

  return builder.join('/')
}

/**
 * Manages hostnames for multi-sites, base on the environment will build the correct url.
 *
 * @params hostname: string;
 * @params environment?: string;
 * @retuns string
 */

export const hostNameToUrl = (hostname: string, environment = 'local') => {
  switch (environment) {
    case 'production':
      return `https://${hostname}`
    case 'preview':
    case 'development':
      return `https://stage-${hostname}`
    default:
      return `http://localhost:3000`
  }
}
